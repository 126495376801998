<template>
  <Navbar />
  <router-link to="/participant">
        <ui-fab icon="keyboard_backspace" style="margin-top: 30px;"></ui-fab>
    </router-link>
  <section class="first-screen">

    <div class="container first-screen__inner">
      <img src="../assets/images/1cd696b559967f49.svg" alt="illustration">
      <router-link to="/signup"><button class="action-button">Подать заявку</button></router-link>
      <p>{{ this.$store.state.user.name }}, вы&nbsp;еще не&nbsp;зарегистрировали ни&nbsp;одного выступления. Подайте заявку на&nbsp;участие,
        и&nbsp;она отразится здесь</p>
    </div>
  </section>
</template>
    
<script>
export default {
  data() {
    return {
    }
  },
  mounted() {
    this.numberOfPerformances()
  },
  methods: {
    numberOfPerformances() {
      if (this.$store.state.vkUserPerformances.length > 0) {
        this.$router.push('personal-area')
      }
    },
  }
}
</script>
