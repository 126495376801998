<template>
  <header class="header">
  <div class="container header__inner">
    <div class="header__text">
      <h1>Малиновое небо</h1>
      <p>Фестиваль воздушной гимнастики</p>
    </div>
    <div header__link>
      <a href="https://vk.com/malinovoenebo_fest" target="_blank">
        <img src="../assets/images/vk_icons.svg" alt="vk">
      </a>
    </div>
  </div>
</header>
</template>

<script>
export default {

}
</script>

<style></style>