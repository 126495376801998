<template>
    <section class="plug">
        <div class="container plug__inner">
            <p>
                {{ this.$store.state.stopMessage }}
            </p>
        </div>
    </section>

</template>

<script>
export default {
    data() {
        return {

        }
    }
}
</script>
<style scoped>
.plug {
    margin: 80px 0;
}

.plug__inner {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.plug__inner p {
    font-family: 'Raleway-Medium';
    color: #1A193B;
    font-size: 20px;
    line-height: 30px;
    text-align: center;
    max-width: 535px;
}

</style>