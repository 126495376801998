<template>
    <fieldset class="form__fieldset"> 
        <legend class="form__legend">Данные партнера по дуэту</legend>
        <div class="fieldset-list">
            <div class="fieldset-list__item">
                <div class="form__group">
                    <input type="text" class="form__input" v-model="v$.surname.$model" placeholder="">
                    <label class="form__label">Фамилия</label>
                </div>
                <template v-if="performanceType === 'дуэт'">
                    <p v-for="(error, index) in v$.surname.$errors"  :key="index" class="form__error">{{ error.$message }}</p>
                </template>
                <div class="form__group">
                    <input type="text" class="form__input" v-model="v$.name.$model" placeholder="">
                    <label class="form__label">Имя</label>
                </div>
                <template v-if="performanceType === 'дуэт'">
                    <p v-for="(error, index) in v$.name.$errors"  :key="index" class="form__error">{{ error.$message }}</p>
                </template>
                <div class="form__group">
                    <input type="text" class="form__input" v-model="v$.lastname.$model" placeholder="">
                    <label class="form__label">Отчество</label>
                </div>
                <template v-if="performanceType === 'дуэт'">
                    <p v-for="(error, index) in v$.lastname.$errors"  :key="index" class="form__error">{{ error.$message }}</p>
                </template>
            </div>
        </div>
    </fieldset>
</template>

<script>
import { useVuelidate } from '@vuelidate/core'
import { required, helpers } from '@vuelidate/validators'

export default {
    props: ['performanceType'],
    data() {
        return {
            name: '',
            surname: '',
            lastname: '',
        }
    },
    setup() {
        return { v$: useVuelidate() }
    },
    validations: {
        name: {
            required: helpers.withMessage('Поле "Имя" обязательно для заполнения', required),
        },
        surname: {
            required: helpers.withMessage('Поле "Фамилия" обязательно для заполнения', required),
        },
        lastname: {
            required: helpers.withMessage('Поле "Отчество" обязательно для заполнения', required),
        },
    },
    watch: {
        name: function() {
            this.$emit('getPartnerName', this.name)
        },
        surname: function() {
            this.$emit('getPartnerSurname', this.surname)
        },
        lastname: function () {
            this.$emit('getPartnerLastname', this.lastname);
        }
    }
}
</script>

<style></style>