<template>
    <h2>Для доступа к приложению требуется разрешение на получение сообщений от сообщества!</h2>
    <button @click="getMessageGroup" class="action-button">Разрешить</button>
</template>

<script>
import bridge from '@vkontakte/vk-bridge';

export default {
    data() {
        return {

        }
    },
    methods: {
        getMessageGroup() {
            bridge.send('VKWebAppAllowMessagesFromGroup', {
                group_id: 221335911,
                key: 'dBuBKe1kFcdemzB'
            })
                .then((data) => {
                    if (data.result) {
                        this.$store.state.permissionMessageApp = true
                        this.$router.push('/')
                        // Пользователь разрешил отправку сообщений от имени сообщества
                        console.log(data.result)
                    }
                })
                .catch((error) => {
                    // Ошибка
                    console.log(error);
                });
        },
    }
}
</script>

<style></style>