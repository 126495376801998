<template>
   <section class="third-coach-screen">
        <div class="container third-coach-screen__inner">
            <router-link to="coach-start">
                <button class="back__btn">
                    <svg width="81" height="42" viewBox="0 0 81 42" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <circle cx="21" cy="21" r="21"/>
                        <path d="M27.2642 15.75L20.3951 19.6786C20.3951 19.6786 19.3105 20.2471 19.3105 20.75C19.3105 21.2529 20.3951 21.8214 20.3951 21.8214L27.2642 25.75" stroke-width="2.7" stroke-linecap="round"/>
                        <path d="M21.4655 20.7498H79.3106" stroke-width="2.7" stroke-linecap="round"/>
                    </svg>                                     
                </button>
            </router-link>
            <div class="third-coach-screen__content content">
                <p class="description">Пожалуйста, укажите свои данные.</p>

                <form @submit.prevent="checkCoach()" class="form">
                    <div class="data-form">
                        <input class="data-form__input data-form__input_full-name" type="text" placeholder="ФИО">
                    </div>

                    <div class="data-form">
                        <input class="data-form__input" type="tel" placeholder="Телефон">
                    </div>

                    <div class="form__privacy">
                        <div class="form__checkbox form__checkbox_flex">
                            <input type="checkbox" id="check-privacy" class="form__input-checkbox">
                            <label for="check-privacy" class="form__label-checkbox">Отправляя форму вы&nbsp;соглашаетесь на&nbsp;обработку персональных данных</label>
                        </div>
                    </div>

                    <div class="data-form__btn-send-flex">
                        <button class="data-form__btn-send__primary_btn primary__btn">Отправить</button>
                    </div>
                </form>
                
            </div>
        </div>
    </section>
</template>

<script>
export default {
    data() {
        return {

        }
    },
    methods: {
        checkCoach() {
            this.$router.push('success-request')
        }
    }
}
</script>

<style>
.data-form__btn-send-flex {
    display: flex;
    justify-content: flex-end;
}

.primary__btn:hover {
    background-color: #8A94F0;
}
.data-form__btn-send__primary_btn {
    font-size: 16px;
    line-height: 16px;
    padding: 12px 32px;
    max-width: 150px;
}
.primary__btn {
    font-family: 'Raleway-SemiBold';
    background-color: #d546c4;
    border-radius: 8px;
    color: #ffffff;
    width: 100%;
    margin: 15px 0;
    transition: all .3s ease-in-out;
}

</style>