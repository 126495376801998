<template>
    <div class="form__privacy">
        <div class="form__checkbox form__checkbox_flex">
            <input type="checkbox" id="check-privacy" class="form__input-checkbox"
                @click="confirmationData = !confirmationData">
            <label for="check-privacy" class="form__label-checkbox">Отправляя форму вы соглашаетесь на
                обработку персональных данных</label>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            confirmationData: false
        }
    },
    watch: {
        confirmationData: function() {
            this.$emit('getConfirmationData', this.confirmationData)
        }
    }
}
</script>

<style></style>