<template>
    <detailCard @closed="closed" v-if="openCard" :performance="performance" />

    <ui-grid-cell class="demo-cell" columns="6">
        <div class="cards__card" @click="open">
            <div class="cards__status cards__status-waiting-confirmed">
                <status :status="performance.status" />
            </div>

            <h2 class="cards__full_name">{{ performance.user.surname }} {{ performance.user.name }} <br> {{
                performance.user.patronymic }}</h2>
            
            <!-- 
            <ul class="cards__list list">
                <li class="cards-item list-item">
                    <h4 class="cards-item__title">Название выступления</h4>
                    <p class="cards-item__descr">Весенняя заря</p>
                </li>
            </ul> -->
            <div>
                <h5 class="city">Город</h5>
                <h5 class="city" style="float:right" v-if="performance.performance_city_id === 1">Сочи</h5>
                <h5 class="city" style="float:right" v-else-if="performance.performance_city_id === 2">Санкт-Петербург</h5>
                <h5 class="city" style="float:right" v-else>Город выступления не указан</h5>
            </div>
            

            <div class="cards__price" v-if="performance.school || performance.file_or_form === 'form'">
                <h5>Стоимость</h5>
                <h4>{{ performance.price }} ₽</h4>
            </div>
            <div class="cards__btn-expand">
                        
            <button>Развернуть</button>
            </div>

        </div>
    </ui-grid-cell>
</template>

<script>
import status from '@/components/Performance/StatusCard.vue'
import detailCard from '@/components/Performance/DetailCard.vue'

export default {
    props: ['performance'],
    components: { status, detailCard },
    data() {
        return {
            openCard: false,
        }
    },
    methods: {
        open() {
            this.openCard = true
            // document.body.style = 'overflow: hidden;'
        },
        closed() {
            this.openCard = false
            // document.body.style = ''
        },
    }
}
</script>

<style scoped>
.cards__btn-expand h6,
.cards__btn-expand button {
  display: inline-block;
}
h5 {
  display: inline-block;
  justify-content: space-between;
  margin-right: 2px;
}
</style>