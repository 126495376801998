import { createApp } from 'vue'

import App from './App.vue'
import router from './router'
import store from './store'
import { AVPlugin } from 'vue-audio-visual'
// import VueMask from '@devindex/vue-mask';
import BalmUI from 'balm-ui'; 
import 'balm-ui-css';

const app = createApp(App);
// app.use(VueMask)
app.use(store);
app.use(router);
app.use(AVPlugin)
app.use(BalmUI);
app.mount('#app');

