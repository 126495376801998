<template>
    <router-link to="/identification">
        <ui-fab icon="keyboard_backspace" style="margin-top: 30px;"></ui-fab>
    </router-link>

    <checkCoach />
</template>

<script>
import checkCoach from '@/components/Coach/СheckCoach.vue'
export default {
    components: { checkCoach },
    data() {
        return {

        }
    }
}
</script>

<style></style>