<template>
      <fieldset class="form__fieldset">
        <legend class="form__legend">Выберите номинацию в которой будете выступать</legend>
        <div class="fieldset-list">
            <div class="fieldset-list__item" v-for="item in nominations" :key="item">
                <div class="form__radio">
                    <input @input="$emit('update:modelValue', $event.target.value)" type="radio" :id="'nomination'+item.id" name="nomination" :value="item.title" v-model="v$.selected.$model"
                        class="form__input-radio">
                    <label :for="'nomination'+item.id" class="form__label-radio">{{ item.title }}</label>
                </div> 
            </div>
        </div>
    </fieldset>
    <p v-for="(error, index) in v$.selected.$errors" :key="index" style="margin-top: -35px;" class="error-container form__error">{{
        error.$message }}</p>
</template>

<script>
const nominations = [
    { 
        id: 1,
        title: "Арт",
        type: "radio",
    },
    {
        id: 2,
        title: "Спорт",
        type: "radio",
    },
    {
        id: 3,
        title: "Exotic pole",
        type: "radio",
    }
]

import { useVuelidate } from '@vuelidate/core'
import { required, helpers } from '@vuelidate/validators'

export default {
    props: ['modelValue'],
    emits: ['update:modelValue'],
    data() {
        return {
            selected: this.modelValue,
            nominations
        }
    },
    setup() {
        return { v$: useVuelidate() }
    },
    validations: {
        selected: {
            required: helpers.withMessage('Поле "Номинация" обязательно для заполнения', required)
        },
    },
}
</script>

<style>

</style>