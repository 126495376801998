<template>
    <fieldset class="form__fieldset">
        <legend class="form__legend">Выберете участие</legend>
        <div class="fieldset-list">
            <div class="fieldset-list__item" v-for="item in commandChecked" :key="item">
                <div class="form__radio"> 
                    <input :type="item.type" :id="'command' + item.id" :value="item.value" name="command" class="form__input-radio"
                        v-model="v$.command.$model">
                    <label :for="'command' + item.id" class="form__label-radio">{{ item.title }}</label>
                </div>
            </div>
        </div>
    </fieldset>
    <p v-for="(error, index) in v$.command.$errors" :key="index" style="margin-top: -35px;" class="error-container form__error">{{ error.$message }}</p> 
</template>

<script>
const commandChecked = [
    {
        id: 1,
        type: "radio",
        title: "Участие без команды",
        value: 0,
    },
    {
        id: 2,
        type: "radio",
        title: "Командное участие",
        value: 1,
    }
]

import { useVuelidate } from '@vuelidate/core'
import { required, helpers } from '@vuelidate/validators'

export default {
    data() {
        return {
            commandChecked,
            command: null,
        }
    },
    setup() {
        return { v$: useVuelidate() }
    },
    validations: {
        command: {
            required: helpers.withMessage('Поле "Командное участие" обязательно для заполнения', required)
        },
    },
    watch: {
        command: function() {
            this.$emit('getCommandSelect', this.command)
        }
    }
}
</script>

<style></style>