<template>
  <div class="maintenance-message">
    <h1>Приносим свои извенения, за доставленное неудобство!
      Сейчас ведутся технические работы, приложение будет доступно чуть позже!
      Спасибо за понимание!</h1>
    <p>Пожалуйста, зайдите позже.</p>
  </div>
</template>

<script>
export default {
  name: 'MaintenanceComponent'
}
</script>

<style scoped>
.maintenance-message {
  width: 50%;
  margin: 10% auto;
  padding: 20px;
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.1);
  text-align: center;
}

h1 {
  color: #333;
}

p {
  color: #666;
}

@media screen and (max-width: 600px) {
  .maintenance-message {
    width: 90%;
  }

  h1 {
    font-size: 1.2em;
  }

  p {
    font-size: 0.9em;
  }
}
</style>