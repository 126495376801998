<template>
    <fieldset class="form__fieldset">
        <legend class="form__legend">Выберите снаряд с которым планируете выступать</legend>
        <div class="fieldset-list">
            <div class="fieldset-list__item" v-for="item in projectiles" :key="item">
                <div class="form__radio">
                    <input @click="getProjectile(item)" type="radio" :id="'snaryad'+item.id" name="snaryad" :value="item.title" v-model="v$.selectedAmmunition.$model"
                        class="form__input-radio">
                    <label :for="'snaryad'+item.id" class="form__label-radio">{{ item.title }}</label>
                </div>
            </div>
        </div>
    </fieldset>
    <p v-for="(error, index) in v$.selectedAmmunition.$errors" :key="index" style="margin-top: -35px;" class="error-container form__error">{{ error.$message }}</p> 
</template>
<script>
import { useVuelidate } from '@vuelidate/core'
import { required, helpers } from '@vuelidate/validators'
import axios from 'axios'

export default {
    data() {
        return {
            selectedAmmunition: '',
            projectiles: [], 
        }
    },
    setup() {
        return { v$: useVuelidate() }
    },
    mounted() {
        this.getProjectiles()
    },
    validations: {
        selectedAmmunition: {
            required: helpers.withMessage('Поле "Снаряд" обязательно для заполнения', required)
        },
    },
    methods: {
        getProjectile(projectile) {
            this.$emit('getProjectile', projectile)
        },
        getProjectiles() {
            axios.get(`${process.env.VUE_APP_URL}/api/projectiles`)
            .then((res) => {
                this.projectiles = res.data
            })
            .catch((err) => {
                console.log(err)
            })
        }
    },
}
</script>

<style></style>