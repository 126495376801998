<template>
    <ui-dialog v-model="open" @confirm="onConfirm">
        <ui-dialog-title>Неверный Формат файла</ui-dialog-title>
        <ui-dialog-content>
            <p>
             Для подачи заявки скачайте и заполните шаблон формата .xlsx 
            </p>
        </ui-dialog-content>
        <div class="d-flex justify-content-end m-3">
            <a href="https://appdev.inform-s.ru/storage/template.xlsx">
                <ui-button style="margin-right: 30px;" raised>Скачать шаблон</ui-button>
            </a>
            <ui-button raised @click="open = !open">Ок</ui-button>
        </div>
        <!-- <ui-dialog-actions></ui-dialog-actions> -->
    </ui-dialog>

    <section class="application-submission-by-file">
        <div class="container application-submission-by-file__inner">
            <router-link to="/coach-start">
                <button class="back__btn">
                    <svg width="81" height="42" viewBox="0 0 81 42" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <circle cx="21" cy="21" r="21" />
                        <path
                            d="M27.2642 15.75L20.3951 19.6786C20.3951 19.6786 19.3105 20.2471 19.3105 20.75C19.3105 21.2529 20.3951 21.8214 20.3951 21.8214L27.2642 25.75"
                            stroke-width="2.7" stroke-linecap="round" />
                        <path d="M21.4655 20.7498H79.3106" stroke-width="2.7" stroke-linecap="round" />
                    </svg>
                </button>
            </router-link>
            <div class="application-submission-by-file__content content">
                <p class="description">Мы&nbsp;разработали единый шаблон заявок для всех участников. Скачивайте его,
                    заполните и&nbsp;загрузите на&nbsp;этой странице. Если у&nbsp;вас возникнут вопросы, задайте
                    их&nbsp;в&nbsp;чате поддержки.</p>
                <a class="second-coach-screen__btn secondary__btn"
                    href="https://appdev.inform-s.ru/api/coach-archive">Скачать шаблон</a>
                <label class="second-coach-screen__btn secondary__btn">
                    <input class="second-coach-screen__btn secondary__btn" type="file" ref="receipt"
                        v-on:change="download()" />
                    Загрузить шаблон
                </label>
            </div>
        </div>
    </section>
</template>

<script>
import axios from 'axios'

export default {
    data() {
        return {
            file: '',
            open: false

        }
    },
    methods: {
        onConfirm(result) {
            if (result) {
                console.log('ok');
            } else {
                console.log('cancel');
            }
        },
        checkXlsxFile(str) {
            return str.endsWith('.xlsx');
        },
        download() {

            this.file = this.$refs.receipt.files[0]

            if (!this.checkXlsxFile(this.file['name'])) {
                // this.$alert(`Неверный формат файла, для подачи заявки скачайте шаблон формата .xlsx`);
                this.open = true
                return false
            }

            console.log(this.file)
            if (!this.file) return
            let formData = new FormData();
            const config = { 'content-type': 'multipart/form-data' }
            // localStorage.getItem('vk_user_id')
            // 715104636
            formData.append('vk_id', localStorage.getItem('vk_user_id'));
            formData.append('file', this.file);

            axios.post(`${process.env.VUE_APP_URL}/api/performances-create`, formData, config)
                .then((res) => {
                    this.getUserPerformances()
                    this.createVkMessage()
                    console.log(res)
                })
                .catch((err) => {
                    console.log(err)
                    this.$alert(err.response.data)
                    setTimeout(() => {
                        this.$router.push('/coach-start')                        
                    }, 3000)
                })
        },
        getUserPerformances() {
            // -unique
            axios.post(`${process.env.VUE_APP_URL}/api/user-performance`, {
                // vk_id: 715104636,
                vk_id: localStorage.getItem('vk_user_id'),
            })
                .then((res) => {

                    this.$store.state.vkUserPerformances = res.data

                    if (this.$store.state.permissionMessageApp) {
                        if (this.$store.state.vkUserPerformances.length > 0) this.$router.push('/personal-area')
                    }

                })
                .catch((err) => {
                    console.log(err)
                })
        },
        createVkMessage() {
            let vk_name = localStorage.getItem('name');
            let vk_user_id = localStorage.getItem('vk_user_id');
            let message = `
                ✅ ${vk_name}, командная заявка успешно подана, ожидайте подтверждения организатора..
             `
            axios.get(`https://vkbotdev.inform-s.ru/?message=${message}&user_id=${vk_user_id}`)
                .then((res) => {
                    console.log(res)
                })
                .catch((err) => {
                    console.log(err)
                })
        },
    }
}
</script>

<style scoped>
.secondary__btn {
    cursor: pointer;
    font-family: 'Raleway-Medium';
    background: rgba(213, 70, 196, 0.15);
    color: rgba(26, 25, 59, 0.8);
    font-size: 18px;
    line-height: 28px;
    border-radius: 8px;
    padding: 10px 30px;
    width: 100%;
    margin: 15px 0;
    transition: all 0.3s ease-in-out;
}

.secondary__btn:hover {
    background: rgba(213, 70, 196, 0.30);
}
@media (max-width: 395px) {
    .first-screen__inner a {
        padding: 10px 20px;
    }
}
</style>