import { createStore } from 'vuex'

export default createStore({
  state: {
    permissionMessageApp: true,
    user: {
      name: '',
    },
    vkUserPerformances: [], 
    coaches: [],
    schools: [],
    stopMessage: '',
  },
  getters: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
  }
})
