<template>
      <section class="second-participant-screen">
        <div class="container second-participant-screen__inner">
          <router-link to="/identification">
            <button class="back__btn">
                <svg width="81" height="42" viewBox="0 0 81 42" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <circle cx="21" cy="21" r="21"/>
                    <path d="M27.2642 15.75L20.3951 19.6786C20.3951 19.6786 19.3105 20.2471 19.3105 20.75C19.3105 21.2529 20.3951 21.8214 20.3951 21.8214L27.2642 25.75" stroke-width="2.7" stroke-linecap="round"/>
                    <path d="M21.4655 20.7498H79.3106" stroke-width="2.7" stroke-linecap="round"/>
                </svg>                                     
            </button>
          </router-link>
            <div class="second-participant-screen__content content">
                <p class="description">{{ this.$store.state.user.name }}, если у&nbsp;вас ещё нет зарегистрированных выступлений, пожалуйста, подайте заявку на&nbsp;участие, чтобы она отобразилась здесь. <br> Если вы&nbsp;уже зарегистрированы, вы&nbsp;можете проверить статус вашей заявки.</p>
                <router-link class="second-participant-screen__primary_btn primary__btn" to="/signup">
                  Подать заявку
                </router-link>
                <!-- <router-link class="second-participant-screen__secondary_btn secondary__btn secondary__btn" to="/check-status">
                  Проверить статус моей заявки
                </router-link> -->
            </div>
        </div>
    </section>
</template>

<script>
export default {
  data() {
    return {
      visible: true,
    }
  },
  methods: {
      visibleSignupButton() {
        this.visible = !this.visible
      }
    }
}
</script>

<style scoped>
.container {
    max-width: 900px;
    padding: 0 50px;
    margin: 0 auto;
}

.back__btn {
    width: 80px;
    height: 42px;
    color: #F9E3F6;
    margin: 35px 0;
    transition: color 0.3s ease-in-out;
}

.back__btn svg {
    display: block;
    width: 100%;
    height: 100%;
    fill: currentColor;
    object-fit: cover;
}

.back__btn svg path {
    stroke: #000000;
}

.back__btn:hover {
    color: rgba(213, 71, 197, 0.3);
}

.content {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.description {
    font-family: 'Raleway-Medium';
    font-size: 18px;
    line-height: 28px;
    color: #1A193B;
    margin-bottom: 35px;
    text-align: center;
}

.secondary__btn {
    font-family: 'Raleway-Medium';
    background: rgba(213, 70, 196, 0.15);
    color: rgba(26, 25, 59, 0.8);
    font-size: 18px;
    line-height: 28px;
    border-radius: 8px;
    padding: 10px 30px;
    width: 100%;
    margin: 15px 0;
    text-align: center;
    transition: all 0.3s ease-in-out;
}
.secondary__btn:hover{
    background: rgba(213, 70, 196, 0.30);
}

.primary__btn {
    font-family: 'Raleway-SemiBold';
    background-color: #d546c4;
    border-radius: 8px;
    color: #ffffff;
    width: 100%;
    margin: 15px 0;
    transition: all .3s ease-in-out;
    text-align: center;
  }
.primary__btn:hover {
    background-color: #8A94F0;
}

.hr {
    margin: 40px 0;
}

/* header */

.header {
    background: linear-gradient(90deg, rgb(222, 73, 189), rgb(173, 58, 226) 45%, rgb(123, 185, 245) 100%);
    padding: 31px 0 26px 0;
}

.header__inner {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.header__text {
    color: #ffffff;
}

.header__text h1 {
    font-family: 'Raleway-Bold';
    font-weight: 700;
    font-size: 24px;
    line-height: 24px;
    margin-bottom: 10px;
}

.header__text p {
    font-family: 'Raleway-Medium';
    font-size: 16px;
    line-height: 18px;
}

.header__link {
    width: 32px;
    height: 32px;
    color: #ffffff;
    transition: color 0.3s ease-in-out;
}

.header__link svg {
    display: block;
    width: 100%;
    height: 100%;
    fill: currentColor;
    object-fit: cover;
}

.header__link:hover {
    color: #e1e1e1;
}

/* header end*/

/* second participant screen */

.second-participant-screen__content p {
    max-width: 670px;
}

.second-participant-screen__primary_btn {
    font-size: 20px;
    line-height: 20px;
    padding: 16px 30px;
    max-width: 345px;
}

.second-participant-screen__secondary_btn {
    max-width: 345px;
}
/* second participant screen end */

/* third participant screen */

.form {
    display: flex;
    flex-direction: column;
    gap: 25px;
    max-width: 740px;
    width: 100%;
}

.data-form {
    display: flex;
    justify-content: space-between;
    width: 100%;
    background-color: #ffffff;
    border-bottom: 1px solid #eeeeee;
}

.data-form__input {
    resize: none;
    max-width: 700px;
    width: 100%;
    padding: 5px 10px;
    color: #1A193B;
}

.data-form__input::placeholder {
    color: #737373;
}

.data-form__input,
.data-form__input::placeholder {
    font-family: 'Raleway-Medium';
    font-size: 18px;
    line-height: 28px;
}


.data-form__btn-icon {
    width: 30px;
    height: 30px;
    color: #ffffff;
    transition: color 0.2s ease-in-out;
}

.data-form__btn-icon svg {
    display: block;
    width: 100%;
    height: 100%;
    fill: currentColor;
    object-fit: cover;
}

.data-form__btn-icon:hover {
    color: rgba(213, 71, 197, 0.1);
}

.data-form__btn-send-flex {
    display: flex;
    justify-content: flex-end;
}

.data-form__btn-send__btn {
    font-size: 16px;
    line-height: 16px;
    padding: 12px 32px;
    max-width: 150px;
}


.form__checkbox {
    position: relative;
}

.form__checkbox_flex {
    display: flex;
}

.form__input-checkbox {
    position: absolute;
    width: 0;
    height: 0;
    border: none;
    opacity: 0;
}

.form__label-checkbox {
    display: flex;
    align-items: center;
    transition: all .3s;
    font-size: 16px;
    line-height: 20px;
    color: #1A193B;
}

.form__label-checkbox:before {
    content: "";
    display: block;
    width: 30px;
    height: 30px;
    flex: 0 0 30px;
    margin-right: 10px;
    border: 2px solid #EAA2E1;
    transition: all .3s;
    border-radius: 5px;
}

.form__input-checkbox:checked+.form__label-checkbox {
    color: #D546C4;
}

.form__input-checkbox:checked+.form__label-checkbox:before {
    background-image: url('../assets/images/checkbox.svg');
    background-color: #EAA2E1;
    background-repeat: no-repeat;
    background-position: 50%;
}
/* third participant screen end*/

/* request sent */

.request-sent {
    margin: 100px 0;
}

.request-sent__inner {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 auto;
    background: #FFFFFF;
    box-shadow: 0px 0px 10px 1px #DCDCDC;
    border-radius: 15px;
    padding: 55px 70px 84px;
    max-width: 500px;
    width: 100%;
}

.request-sent__inner img {
    margin-bottom: 20px;
}

.request-sent__inner h2 {
    font-family: 'Raleway-SemiBold';
    margin: 10px 0;
    font-size: 24px;
    line-height: 35px;
    color: #000000;
}

.request-sent__inner p {
    font-family: 'Raleway-Regular';
    font-size: 15px;
    line-height: 18px;
    text-align: center;
    color: rgba(26, 25, 59, 0.6);
    max-width: 358px;
}

/* request sent end */

@media (max-width: 650px){
    .container {
        padding: 0 30px;
    }
    
    .header {
        padding: 20px 0;
    }

    .second-participant-screen__content p {
        max-width: 500px;
    }
}

@media (max-width: 500px){
    .container {
        padding: 0 25px;
    }

    .header__text h1 {
        margin-bottom: 7px;
    }

    .header__link img {
        width: 28px;
    }

    .request-sent {
    margin: 60px 0;
    }

    .request-sent__inner {
        padding: 40px 50px 64px;

    }
}

@media (max-width: 450px){
    .container {
        padding: 0 20px;
    }

    .description {
        margin-bottom: 25px;
    }

    .second-participant-screen__content p {
        max-width: 350px;
    }

    .form {
        gap: 20px;
    }

    .request-sent__inner img {
        margin-bottom: 15px;
        width: 45px;
        height: 45px;
    }

    .request-sent__inner h2 {
        margin: 5px 0;
        font-size: 22px;
        line-height: 30px;
    }

    .request-sent__inner p {
        font-size: 13px;
        line-height: 16px;
    }

    .form__label-checkbox {
        font-size: 14px;
        line-height: 18px;
    }
}

</style>