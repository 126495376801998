<template>
      <section class="request-sent">
        <div class="container">
            <div class="request-sent__inner">
                <div><img src="../assets/images/request_sent_icon.svg" alt="tick icon"></div>
                <h2>Запрос отправлен</h2>
                <p>Вам придёт уведомление, когда администратор подтвердит запрос</p>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    data() {
        return {

        }
    },
    mounted() {
        setTimeout(() => {
            this.$router.push('/')
        }, 3000)
    }
}
</script>

<style>

</style>