<template>
  <MaintenanceComponent v-if="appIsDown" />
  <GridLoader v-if="loading" :loading="loading" color="#de49bd" :size="'100px'" class="loader-postion"/>
  <template v-else-if="!appIsDown">
    <Navbar/>
    <div class="container">
      <router-view/>
    </div>
  </template>
</template>

<script>
import {GridLoader} from "vue-spinner/dist/vue-spinner.min.js";
import Navbar from '@/components/NavbarUI.vue'
// import api from '@/api'
import bridge from '@vkontakte/vk-bridge';
import axios from 'axios'
import MaintenanceComponent from "@/components/MaintenanceComponent.vue";
// import {connectionVkService} from '@/services/connectionVkService.ts'

export default {
  name: 'HomeView',
  components: {
    MaintenanceComponent,
    Navbar,
    GridLoader,
  },
  data() {
    return {
      domen: process.env.VUE_APP_URL,
      bookTitle: '',
      loading: false,
      phone: '',
      appIsDown: false //поменять на true что бы была заглушка
    }
  },
  mounted() {
    if (this.$store.state.permissionMessageApp === false) this.$router.push('/permission-dined')
  },
  async created() {
    // this.getUserPerformances()
    this.getCoaches()
    this.getSchools()

    this.loading = true

    bridge.send('VKWebAppInit');

    bridge.send('VKWebAppGetClientVersion');

    bridge.send('VKWebAppGetLaunchParams');

    this.getMessageGroup()

    bridge.subscribe((res) => {
      console.log(res)

      let user_id = res.detail.data.vk_user_id ?? null

      if (user_id) {
        this.getUserInfo(user_id)
        this.getUserPerformances()

        if (this.$store.state.permissionMessageApp) {
          if (this.$store.state.vkUserPerformances.length > 0) {
            this.$router.push('/personal-area')
          } else {
            this.$router.push('/identification')
          }
        }


        this.loading = false
      }

    });

  },
  methods: {
    getCoaches() {
      axios.get(`${process.env.VUE_APP_URL}/api/coaches`)
          .then((res) => {
            this.$store.state.coaches = res.data
            console.log(res)
          })
          .catch((err) => {
            console.log(err)
          })
    },
    getSchools() {
      axios.get(`${process.env.VUE_APP_URL}/api/schools`)
          .then((res) => {
            this.$store.state.schools = res.data.schools
            console.log(res)
          })
          .catch((err) => {
            console.log(err)
          })
    },
    getUserPerformances() {
      // -unique
      axios.post(`${process.env.VUE_APP_URL}/api/user-performance`, {
        // vk_id: 715104636,
        vk_id: localStorage.getItem('vk_user_id'),
      })
          .then((res) => {
            console.log(res)
            this.$store.state.permissionMessageApp = true
            this.$store.state.vkUserPerformances = res.data

            if (this.$store.state.permissionMessageApp) {
              if (this.$store.state.vkUserPerformances.length > 0) {
                this.$router.push('/personal-area')
              } else {
                this.$router.push('/identification')
              }
            }

            this.fetchAppStatus();

          })
          .catch((err) => {
            console.log(err)
          })
    },
    getMessageGroup() {
      bridge.send('VKWebAppAllowMessagesFromGroup', {
        group_id: 212129777,
        key: 'dBuBKe1kFcdemzB'
      })
          .then((data) => {
            if (data.result) {
              this.$store.state.permissionMessageApp = true

              if (this.$store.state.permissionMessageApp) {
                if (this.$store.state.vkUserPerformances.length > 0) {
                  this.$router.push('/personal-area')
                } else {
                  this.$router.push('/identification')
                }
              }

              // this.$router.push('identification')
              // Пользователь разрешил отправку сообщений от имени сообщества
              console.log(data.result)
            }
          })
          .catch((error) => {
            // this.$router.push('/permission-dined')
            this.getMessageGroup()
            // Ошибка
            console.log(error);
          });
    },
    fetchAppStatus() {
      axios.get(`${process.env.VUE_APP_URL}/api/app-status`)
          .then((res) => {
            if (res.data[0].status === 'active') {
              return true
            } else {
              this.$store.state.stopMessage = res.data[0].message
              this.$router.push('/stop')
              return false
            }
            // console.log(res)
          })
          .catch((err) => {
            console.log(err)
          })
    },
    getUserInfo(user_id) {
      bridge.send('VKWebAppGetUserInfo', {
        user_id: user_id
      })
          .then((data) => {
            if (data.id) {
              this.$store.state.userdata = data
              let user_id = data.id
              let first_name = data.first_name
              let surname = data.last_name
              this.$store.state.user.name = first_name
              localStorage.setItem('vk_user_id', user_id)
              localStorage.setItem('name', first_name)
              localStorage.setItem('surname', surname)
              this.createUser(first_name, surname, user_id)
              console.log(this.$store.state.userdata)
            }
          })
          .catch((error) => {
            console.log(error);
          });
    },
    createUser(first_name, surname, vk_id) {
      axios.post(`${process.env.VUE_APP_URL}/api/create-vkuser`, {
        name: first_name,
        surname: surname,
        total_price: 0,
        vk_id: vk_id,
      })
          .then((res) => {
            console.log(res)
          })
          .then((err) => {
            console.log(err)
          })
    }
  }
}
</script>

<style>
*,
*::after,
*::before {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body::-webkit-scrollbar {
  display: none;
}

ul {
  margin: 0;
  list-style: none;
}

li {
  padding-left: 0;
}

a {
  text-decoration: none;
  color: inherit;
}

ul {
  margin: 0;
  list-style: none;
}

li {
  padding-left: 0;
}

a {
  text-decoration: none;
  color: inherit;
}

button {
  display: inline-block;
  cursor: pointer;
  border: none;
  background-color: transparent;
  padding: 0;
}

input {
  display: block;
  outline: none;
  border: none;
}

.container {
  max-width: 900px;
  padding: 0 50px;
  margin: 0 auto;
}

.back__btn {
  width: 80px;
  height: 42px;
  color: #F9E3F6;
  margin: 35px 0;
  transition: color 0.3s ease-in-out;
}

.back__btn svg {
  display: block;
  width: 100%;
  height: 100%;
  fill: currentColor;
  object-fit: cover;
}

.back__btn svg path {
  stroke: #000000;
}

.back__btn:hover {
  color: rgba(213, 71, 197, 0.3);
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.description {
  font-family: 'Raleway-Medium';
  font-size: 18px;
  line-height: 18px;
  text-align: justify;
  color: #1A193B;
  margin-bottom: 35px;
  /* text-align: center; */
}

.secondary__btn {
  font-family: 'Raleway-Medium';
  background: rgba(213, 70, 196, 0.15);
  color: rgba(26, 25, 59, 0.8);
  font-size: 18px;
  line-height: 28px;
  border-radius: 8px;
  padding: 10px 30px;
  width: 100%;
  margin: 15px 0;
  transition: all 0.3s ease-in-out;
}

.secondary__btn:hover {
  background: rgba(213, 70, 196, 0.30);
}

.primary__btn {
  font-family: 'Raleway-SemiBold';
  background-color: #d546c4;
  border-radius: 8px;
  color: #ffffff;
  width: 100%;
  margin: 15px 0;
  transition: all .3s ease-in-out;
}

.primary__btn:hover {
  background-color: #8A94F0;
}

.hr {
  margin: 40px 0;
}

/* header */

.header {
  background: linear-gradient(90deg, rgb(222, 73, 189), rgb(173, 58, 226) 45%, rgb(123, 185, 245) 100%);
  padding: 31px 0 26px 0;
}

.header__inner {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.header__text {
  color: #ffffff;
}

.header__text h1 {
  font-family: 'Raleway-Bold';
  font-weight: 700;
  font-size: 24px;
  line-height: 24px;
  margin-bottom: 10px;
}

.header__text p {
  font-family: 'Raleway-Medium';
  font-size: 16px;
  line-height: 18px;
}

.header__link {
  width: 32px;
  height: 32px;
  color: #ffffff;
  transition: color 0.3s ease-in-out;
}

.header__link svg {
  display: block;
  width: 100%;
  height: 100%;
  fill: currentColor;
  object-fit: cover;
}

.header__link:hover {
  color: #e1e1e1;
}

/* header end*/

/* second participant screen */

.second-participant-screen__content p {
  max-width: 670px;
}

.second-participant-screen__primary_btn {
  font-size: 20px;
  line-height: 20px;
  padding: 16px 30px;
  max-width: 345px;
}

.second-participant-screen__secondary_btn {
  max-width: 345px;
}

/* second participant screen end */

/* third participant screen */

.form {
  display: flex;
  flex-direction: column;
  gap: 25px;
  max-width: 740px;
  width: 100%;
}

.data-form {
  display: flex;
  justify-content: space-between;
  width: 100%;
  background-color: #ffffff;
  border-bottom: 1px solid #eeeeee;
}

.data-form__input {
  resize: none;
  max-width: 700px;
  width: 100%;
  padding: 5px 10px;
  color: #1A193B;
}

.data-form__input::placeholder {
  color: #737373;
}

.data-form__input,
.data-form__input::placeholder {
  font-family: 'Raleway-Medium';
  font-size: 18px;
  line-height: 28px;
}

.data-form__input_full-name {
}

.data-form__btn-icon {
  width: 30px;
  height: 30px;
  color: #ffffff;
  transition: color 0.2s ease-in-out;
}

.data-form__btn-icon svg {
  display: block;
  width: 100%;
  height: 100%;
  fill: currentColor;
  object-fit: cover;
}

.data-form__btn-icon:hover {
  color: rgba(213, 71, 197, 0.1);
}

.data-form__btn-send-flex {
  display: flex;
  justify-content: flex-end;
}

.data-form__btn-send__btn {
  font-size: 16px;
  line-height: 16px;
  padding: 12px 32px;
  max-width: 150px;
}


.form__checkbox {
  position: relative;
}

.form__checkbox_flex {
  display: flex;
}

.form__input-checkbox {
  position: absolute;
  width: 0;
  height: 0;
  border: none;
  opacity: 0;
}

.form__label-checkbox {
  display: flex;
  align-items: center;
  transition: all .3s;
  font-size: 16px;
  line-height: 20px;
  color: #1A193B;
}

.form__label-checkbox:before {
  content: "";
  display: block;
  width: 30px;
  height: 30px;
  flex: 0 0 30px;
  margin-right: 10px;
  border: 2px solid #EAA2E1;
  transition: all .3s;
  border-radius: 5px;
}

.form__input-checkbox:checked + .form__label-checkbox {
  color: #D546C4;
}

.form__input-checkbox:checked + .form__label-checkbox:before {
  background-image: url('assets/images/checkbox.svg');
  background-color: #EAA2E1;
  background-repeat: no-repeat;
  background-position: 50%;
}

/* third participant screen end*/

/* request sent */

.request-sent {
  margin: 100px 0;
}

.request-sent__inner {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
  background: #FFFFFF;
  box-shadow: 0px 0px 10px 1px #DCDCDC;
  border-radius: 15px;
  padding: 55px 70px 84px;
  max-width: 500px;
  width: 100%;
}

.request-sent__inner img {
  margin-bottom: 20px;
}

.request-sent__inner h2 {
  font-family: 'Raleway-SemiBold';
  margin: 10px 0;
  font-size: 24px;
  line-height: 35px;
  color: #000000;
}

.request-sent__inner p {
  font-family: 'Raleway-Regular';
  font-size: 15px;
  line-height: 18px;
  text-align: center;
  color: rgba(26, 25, 59, 0.6);
  max-width: 358px;
}

/* request sent end */

@media (max-width: 650px) {
  .container {
    padding: 0 30px;
  }

  .header {
    padding: 20px 0;
  }

  .second-participant-screen__content p {
    max-width: 500px;
  }
}

@media (max-width: 500px) {
  .container {
    padding: 0 25px;
  }

  .header__text h1 {
    margin-bottom: 7px;
  }

  .header__link img {
    width: 28px;
  }

  .request-sent {
    margin: 60px 0;
  }

  .request-sent__inner {
    padding: 40px 50px 64px;

  }
}

@media (max-width: 450px) {
  .container {
    padding: 0 20px;
  }

  .description {
    margin-bottom: 25px;
  }

  .second-participant-screen__content p {
    max-width: 350px;
  }

  .form {
    gap: 20px;
  }

  .request-sent__inner img {
    margin-bottom: 15px;
    width: 45px;
    height: 45px;
  }

  .request-sent__inner h2 {
    margin: 5px 0;
    font-size: 22px;
    line-height: 30px;
  }

  .request-sent__inner p {
    font-size: 13px;
    line-height: 16px;
  }

  .form__label-checkbox {
    font-size: 14px;
    line-height: 18px;
  }
}

@media (max-width: 400px) {
  .header__text p {
    max-width: 200px;
  }

  .description {
    font-size: 16px;
    margin-bottom: 20px;
  }

  .primary__btn {
    margin: 10px 0;
  }

  .secondary__btn {
    margin: 10px 0;
  }

  .second-participant-screen__content p {
    max-width: 300px;
  }
}

@media (max-width: 395px) {
  .secondary__btn {
    padding: 10px 20px;
  }

  .data-form__input,
  .data-form__input::placeholder {
    font-size: 16px;
    line-height: 26px;
  }
}

@media (max-width: 350px) {
  .container {
    padding: 0 15px;
  }

  .request-sent__inner {
    padding: 30px 40px 54px;
  }
}

.modal__background {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 998;
  background: rgba(0, 0, 0, .5);
}

.container {
  max-width: 1000px !important;
  /* padding: 0 50px; */
  margin: 0 auto;
}

.loader-postion {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

/* .detail__card-position {
    position: fixed;
    z-index: 999;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    transition: opacity 0.3s ease-in-out;

} */

/* header */
.header {
  background: linear-gradient(90deg, rgb(222, 73, 189), rgb(173, 58, 226) 45%, rgb(123, 185, 245) 100%);
  padding: 31px 0 26px 0;
}

.header__inner {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.header__text {
  color: #ffffff;
}

.header__text h1 {
  font-family: 'Raleway-Bold';
  font-weight: 700;
  font-size: 24px;
  line-height: 24px;
  margin-bottom: 10px;
}

.header__text p {
  font-family: 'Raleway-Medium';
  font-size: 16px;
  line-height: 18px;
}

.header__link {
  width: 32px;
  height: 32px;
  color: #ffffff;
  transition: color 0.3s ease-in-out;
}

.header__link svg {
  display: block;
  width: 100%;
  height: 100%;
  fill: currentColor;
  object-fit: cover;
}

.header__link:hover {
  color: #e1e1e1;
}

/* header end*/

/* section search and button */

.nav {
  margin: 50px 0;
}

.nav__inner {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.search-form {
  position: relative;
}

.search-form__input::placeholder,
.search-form__input {
  display: inline;
  background-color: transparent;
  font-family: 'Raleway-Medium';
  font-size: 16px;
  line-height: 20px;
  color: #A3A3A3;
}


.search-form__input {
  padding: 15px 40px 15px 5px;
  border-bottom: 2px solid #CFCFCF;
  width: 360px;
  transition: 0.2s ease;
}

.search-form__input:focus {
  transition: 0.2s ease;
  border-bottom: 2px solid #D546C4;
}

.search-form__input::placeholder {
  color: #A3A3A3;
}

.mdc-fab {
  box-shadow: 0px 0px 10px 1px #DCDCDC !important;
  background-color: #7eb4f4 !important;
}

.image-border {
  border-radius: 12px;
}

.form__header {
  border-radius: 12px;
}

.search-form::after {
  content: '';
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  width: 18px;
  height: 18px;
  background: url('assets/images/search.svg');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.nav__btn {
  background-color: #D546C4;
  padding: 15px 45px;
  border-radius: 10px;
  font-family: 'Raleway-SemiBold';
  color: #ffffff;
  font-size: 20px;
  line-height: 25px;
  transition: all 0.3s ease-in-out;
}

.nav__btn:hover {
  background-color: #8A94F0;
}

/* section search and button end */

/* section cards */

.cards {
}

.cards__inner {
}

.cards__container {
  display: flex;
  gap: 60px;
  margin-bottom: 60px;
}

.cards__card {
  cursor: pointer;
  border-radius: 18px;
  max-width: 380px;
  width: 100%;
  padding: 25px 18px 34px;
  background: #FFFFFF;
  color: #1A193B;
  -webkit-box-shadow: 0px 9px 8px 0px rgba(34, 60, 80, 0.2);
  -moz-box-shadow: 0px 9px 8px 0px rgba(34, 60, 80, 0.2);
  box-shadow: 0px 9px 8px 0px rgba(34, 60, 80, 0.2);
  transition: 0.2s ease;
}

.cards__card:hover {
  transition: 0.2s ease-in;
  -webkit-box-shadow: 0px 9px 8px 0px rgba(100, 100, 100, 0.5);
  -moz-box-shadow: 0px 9px 8px 0px rgba(100, 100, 100, 0.5);
  box-shadow: 0px 9px 8px 0px rgba(100, 100, 100, 0.5);
  transform: scale(0.9);
}

.cards__status-confirmed,
.cards__status-waiting-confirmed,
.cards__status-not-confirmed {
  display: flex;
  position: relative;
}

.cards__status-confirmed::before,
.cards__status-waiting-confirmed::before,
.cards__status-not-confirmed::before {
  content: '';
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 22.5px;
  height: 22.5px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.cards__status-confirmed::before {
  background: url('assets/images/confirmed.svg');
}

.cards__status-waiting-confirmed::before {
  background: url('assets/images/waiting_for_confirmation.svg');
}

.cards__status-not-confirmed::before {
  background: url('assets/images/need_to_make_a_change.svg');
}

.cards__status p {
  font-family: 'Raleway-Regular';
  font-size: 16px;
  line-height: 22px;
  padding-left: 35px;
}

.cards__full_name {
  font-family: 'Raleway-Bold';
  line-height: 30px;
  font-size: 22px;
  margin: 35px 0 28px;
}

.cards__list {
  padding: 18px 12px 40px;
  margin-bottom: 18px;
}

.list {
  background-color: #ECEEFF;
  display: flex;
  flex-direction: column;
  gap: 30px;
  border-radius: 15px;
}

.list-item {
  display: flex;
  justify-content: space-between;
}

.cards-item,
.cards-item_pop-up {
  position: relative;
}

.cards-item::after {
  max-width: 300px;
}

.cards-item::after,
.cards-item_pop-up:not(:last-child)::after {
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  bottom: -15px;
  display: block;
  height: 1.5px;
  background: linear-gradient(90deg, rgba(183, 203, 244, 0.5) 0%, rgba(189, 194, 241, 0.5) 71.87%, rgba(207, 206, 243, 0.25) 100%);
}

.cards-item_pop-up:not(:last-child)::after {
  max-width: 490px;
}

.cards-item__title {
  max-width: 120px;
  font-size: 16px;
  line-height: 20px;
}

.cards-item__descr {
  font-family: 'Raleway-Regular';
  max-width: 80px;
  text-align: right;
  font-size: 14.5px;
  line-height: 22px;
}

.cards__price {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;
}

.cards__price h5 {
  font-size: 18px;
  line-height: 22px;
}

.cards__price h4 {
  /* font-family: 'Raleway-Medium'; */
  font-size: 22px;
  line-height: 30px;
}

.cards__btn-expand {
  text-align: right;
}

.cards__btn-expand button {
  position: relative;
  font-family: 'Raleway-Regular';
  font-size: 14px;
  line-height: 14px;
  padding: 6px 32px 6px 6px;
}

.cards__btn-expand button::after {
  content: '';
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 18px;
  height: 18px;
  background: url('assets/images/expand.svg');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.cards__btn-expand button:hover {
  text-decoration: underline;
}

/* section cards end */

/* pop-up */
.cards__pop-ap {
  position: fixed;
  /* display: flex; */
  /* justify-content: center;  */
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 999;
  /* top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); */
  transition: opacity 0.3s ease-in-out;
  /* padding: 10px 80px 10px 20px; */
  /* margin-bottom: 60px; */
  /* background: rgba(76, 76, 76, 0.5); */
}

.cards__pop-ap__inner {
  overflow-y: auto;
  overflow-x: none;
  /* overflow-y: auto; */
  /* overflow-x: auto; */
  position: relative;
  background: #FFFFFF;
  padding: 25px 26px 40px;
  /* border-radius: 25px; */
  border-radius: 0 50px 50px 50;
  /* border-top-right-radius: 50px;
  border-bottom-right-radius: 50px; */
  width: 595px !important;
  height: 100vh;
  color: #1A193B;
}

.cards__pop-ap_close-btn {
  z-index: 999;
  cursor: pointer;
  position: absolute;
  top: 11px;
  right: -10px;
  border: none;
  padding: 10px;
  cursor: pointer;
  margin-right: 10px;
}

.cards__status-confirmed_pop-up,
.cards__status-waiting-confirmed_pop-up,
.cards__status-not-confirmed_pop-up {
  display: flex;
  position: relative;
  min-height: 85px;
  align-items: center;
  border-radius: 12px;
  padding-left: 45px;
}

.cards__status-confirmed_pop-up {
  background: rgba(69, 142, 11, 0.1);
}

.cards__status-waiting-confirmed_pop-up {
  background: rgba(255, 213, 0, 0.1);
}

.cards__status-not-confirmed_pop-up {
  background: rgba(237, 41, 57, 0.1);
}


.cards__status-confirmed_pop-up::before {
  background: url('assets/images/confirmed.svg');
}

.cards__status-waiting-confirmed_pop-up::before {
  background: url('assets/images/waiting_for_confirmation.svg');
}

.cards__status-not-confirmed_pop-up::before {
  background: url('assets/images/need_to_make_a_change.svg');
}

.cards__status-confirmed_pop-up::before,
.cards__status-waiting-confirmed_pop-up::before,
.cards__status-not-confirmed_pop-up::before {
  content: '';
  position: absolute;
  left: 30px;
  top: 50%;
  transform: translateY(-50%);
  width: 32px;
  height: 32px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.cards__status-confirmed_pop-up p,
.cards__status-waiting-confirmed_pop-up p,
.cards__status-not-confirmed_pop-up p {
  color: #000000;
  font-size: 20px;
  line-height: 28px;
}

.cards__status-not-confirmed_pop-up::before {
  top: 30%;
  transform: translateY(-50%);
}

.cards__status-not-confirmed_pop-up span {
  font-family: 'Raleway-Medium';
  font-size: 14px;
  line-height: 20px;
  margin-top: 15px;
  margin-left: 15px;
}

.cards__status-not-confirmed_pop-up {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.cards-subtop {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.cards__full_name_pop-up {
  font-size: 28px;
  line-height: 38px;
  margin: 30px 0 30px;
}

.cards-subtop__btn-edit {
  position: relative;
  color: rgba(0, 0, 0, 0.6);
  font-size: 14px;
  line-height: 28px;
  padding: 6px 6px 6px 32px;
}

.cards-subtop__btn-edit::before {
  content: '';
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 17px;
  height: 17px;
  background: url('assets/images/edit.svg');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.cards-subtop__btn-edit:hover {
  text-decoration: underline;
}

.cards__list_pop-up {
  padding: 18px 25px 30px;
  margin: 30px 0 35px;
}

.cards-item_pop-up {
  position: relative;
}

.cards-item_pop-up:not(:last-child)::after {
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  bottom: -15px;
  display: block;
  height: 1.5px;
  max-width: 490px;
  background: linear-gradient(90deg, rgba(183, 203, 244, 0.5) 0%, rgba(189, 194, 241, 0.5) 71.87%, rgba(207, 206, 243, 0.25) 100%);
}

.list-item__title {
  max-width: 270px;
}

.list-item__descr {
  max-width: 220px;
}

.cards__music {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 25px;
}

.cards__music h5 {
  font-size: 20px;
  line-height: 28px;
  max-width: 250px;
}

.cards__music__btn-download {
  cursor: pointer;
  position: relative;
  background: rgba(213, 70, 196, 0.15);
  border-radius: 8px;
  margin-bottom: 10px;
  font-family: 'Raleway-Medium';
  font-size: 18px;
  line-height: 28px;
  padding: 10px 75px 10px 40px;
  transition: all 0.3s ease-in-out;
}

.cards__music__btn-download::after {
  content: '';
  position: absolute;
  right: 35px;
  top: 50%;
  transform: translateY(-50%);
  width: 20px;
  height: 20px;
  background: url('assets/images/download_music.svg');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.cards__music__btn-download:hover {
  background: rgba(213, 70, 196, 0.30);
}


.cards__price_pop-up h5 {
  font-size: 22px;
  line-height: 28px;
}

.cards__price_pop-up h4 {
  font-size: 25px;
  line-height: 35px;
}

/* pop-up end */

/* @media */

@media (max-width: 800px) {
  .search-form__input {
    width: 320px;
  }
}

@media (max-width: 760px) {
  .search-form__input {
    width: 270px;
  }

  .nav__btn {
    padding: 12px 42px;
    border-radius: 8px;
    font-size: 18px;
    line-height: 22px;
  }

  .cards__container {
    gap: 40px;
    margin-bottom: 40px;
  }
}

@media (max-width: 735px) {

  .cards__status-confirmed,
  .cards__status-waiting-confirmed,
  .cards__status-not-confirmed {
    max-width: 180px;
  }

  .cards__pop-ap {
    padding: 0 50px 0 0;
  }

  .cards__music h5 {
    max-width: 180px;
  }
}

@media (max-width: 650px) {
  .container {
    padding: 0 30px;
  }

  .header {
    padding: 20px 0;
  }

  .cards__container {
    gap: 30px;
    margin-bottom: 30px;
  }
}

@media (max-width: 630px) {
  .nav {
    margin: 40px 0;
  }

  .search-form__input {
    width: 200px;
  }

  .nav__btn {
    padding: 10px 35px;
    border-radius: 6px;
    font-size: 16px;
    line-height: 22px;
  }

  .cards__container {
    flex-direction: column;
    align-items: center;
    gap: 45px;
    margin-bottom: 45px;
  }

  .cards__status-confirmed,
  .cards__status-waiting-confirmed,
  .cards__status-not-confirmed {
    max-width: 260px;
  }
}

@media (max-width: 590px) {

  .cards__pop-ap__inner {
    overflow-y: auto;
    /* overflow-x: auto; */
    border: none !important;
    position: relative;
    background: #FFFFFF;
    padding: 25px 25px 25px;
    /* border-radius: 20px; */
    width: 100% !important;
  }

  .cards__status-confirmed_pop-up::before,
  .cards__status-waiting-confirmed_pop-up::before,
  .cards__status-not-confirmed_pop-up::before {
    left: 20px;
    width: 28px;
    height: 28px;
  }

  .cards__status-not-confirmed_pop-up::before {
    top: 35%;
  }

  .cards__status-not-confirmed_pop-up span {
    margin-top: 10px;
    margin-left: 10px;
  }

  .cards__status-confirmed_pop-up p,
  .cards__status-waiting-confirmed_pop-up p,
  .cards__status-not-confirmed_pop-up p {
    font-size: 18px;
    line-height: 24px;
    padding-left: 15px;
  }

  .cards-subtop {
    display: flex;
    flex-direction: column-reverse;
    align-items: flex-start;
  }

  .cards__full_name_pop-up {
    font-size: 25px;
    line-height: 32px;
    margin: 10px 0 20px;
  }

  .cards-subtop__btn-edit {
    margin: 20px 0 0;
  }

  .cards__list_pop-up {
    margin-top: 10px;
  }

  .cards-item_pop-up {
    display: flex;
    align-items: center;
  }

  .list-item__title {
    max-width: 150px;
  }

  .list-item__descr {
    max-width: 130px;
  }

  .cards__music h5 {
    font-size: 17px;
    line-height: 20px;
    max-width: 180px;
  }

  .cards__music__btn-download {
    border-radius: 8px;
    font-size: 16px;
    line-height: 22px;
    padding: 10px 55px 10px 30px;
    transition: all 0.3s ease-in-out;
  }

  .cards__music__btn-download::after {
    right: 20px;
    width: 17px;
    height: 17px;
  }

  .cards__price {
    margin-bottom: 10px;
  }

  .cards__price_pop-up h5 {
    font-size: 20px;
    line-height: 24px;
  }

  .cards__price_pop-up h4 {
    font-size: 22px;
    line-height: 28px;
  }
}


@media (max-width: 500px) {
  .container {
    padding: 0 25px;
  }

  .header__text h1 {
    margin-bottom: 7px;
  }

  .header__link img {
    width: 28px;
  }

  .nav {
    margin: 30px 0 40px;
  }

  .search-form__input::placeholder,
  .search-form__input {
    display: inline;
    background-color: transparent;
    font-family: 'Raleway-Medium';
    font-size: 16px;
    line-height: 20px;
    color: #A3A3A3;
  }

  .search-form__input {
    padding: 8px 20px 8px 0;
    width: 160px;
  }

  .search-form::after {
    right: 3px;
  }

  /* .cards__pop-ap {
      padding-right: 20px;
  } */
  .cards__pop-ap_close-btn {
    right: -45px;
    padding: 5px;
    margin-right: 5px;
  }

  .cards__pop-ap_close-btn svg {
    width: 25px;
    height: 25px;
  }
}

@media (max-width: 465px) {

  .cards__status-confirmed_pop-up,
  .cards__status-waiting-confirmed_pop-up,
  .cards__status-not-confirmed_pop-up {
    border-radius: 12px;
    padding-left: 40px;
  }

  .cards__status-confirmed_pop-up,
  .cards__status-waiting-confirmed_pop-up {
    min-height: 70px;
  }

  .cards__status-not-confirmed_pop-up {
    min-height: 110px;
  }

  .cards__status-confirmed_pop-up p,
  .cards__status-waiting-confirmed_pop-up p,
  .cards__status-not-confirmed_pop-up p {
    padding-left: 10px;
    max-width: 180px;
    font-size: 16px;
  }

  .cards__status-not-confirmed_pop-up span {
    margin-right: 40px;
  }

  .cards__status-confirmed_pop-up::before,
  .cards__status-waiting-confirmed_pop-up::before,
  .cards__status-not-confirmed_pop-up::before {
    left: 10px;
  }

  .cards__full_name_pop-up {
    font-size: 22px;
    line-height: 28px;
    margin: 5px 0 10px;
  }
}

@media (max-width: 450px) {
  .container {
    padding: 0 20px;
  }

  .nav__inner {
    flex-direction: column;
    gap: 30px;
  }

  .search-form__input::placeholder,
  .search-form__input {
    font-size: 16px;
    line-height: 20px;
  }

  .search-form__input {
    padding: 15px 40px 15px 5px;
    width: 290px;
  }

  .nav__btn {
    padding: 12px 80px;
    border-radius: 10px;
    font-size: 18px;
    line-height: 22px;
  }
}

@media (max-width: 400px) {
  .header__text p {
    max-width: 200px;
  }

  .cards__card {
    max-width: 340px;
    border-radius: 15px;
    padding: 20px 15px 25px;
  }

  .cards__container {
    gap: 35px;
    margin-bottom: 35px;
  }

  .search-form::after {
    right: 6px;
  }

  .cards__pop-ap {
    padding-right: 0;
  }


  .cards__full_name {
    line-height: 28px;
    font-size: 21px;
    margin: 25px 0 22px;
  }

  .cards__price {
    margin-bottom: 20px;
  }

  .cards__price h5 {
    font-size: 17px;
    line-height: 22px;
  }

  .cards__price h4 {
    font-size: 19px;
    line-height: 25px;
  }

  .cards__pop-ap_close-btn {
    z-index: 2;
    top: 45px;
    right: 35px;
    padding: 3px;
    margin-right: 0;
  }

  .cards__pop-ap_close-btn path {
    stroke: #9f9f9f;
  }

  .cards__pop-ap__inner {
    position: relative;
    background: #FFFFFF;
    padding: 20px;
    /* border-radius: 15px; */
  }

  .cards__status-confirmed_pop-up,
  .cards__status-waiting-confirmed_pop-up {
    min-height: 60px;
  }

  .cards__status-confirmed_pop-up,
  .cards__status-waiting-confirmed_pop-up,
  .cards__status-not-confirmed_pop-up {
    max-width: 250px;
  }

  .cards__status-not-confirmed_pop-up {
    min-height: 120px;
  }

  .cards__music {
    flex-direction: column;
    align-items: flex-start;
    gap: 15px;
  }

  .cards__music h5 {
    max-width: 290px;
  }

  .cards__music__btn-download {
    border-radius: 8px;
    padding: 10px 65px 10px 40px;
  }
}

@media (max-width: 360px) {
  .cards__pop-ap__inner {

    padding: 15px;
  }

  .cards__full_name_pop-up {
    margin: 10px 0 18px;
  }

  .cards__list_pop-up {
    padding: 15px 15px 20px;
    margin-bottom: 28px;
  }

  .cards__status-confirmed_pop-up,
  .cards__status-waiting-confirmed_pop-up,
  .cards__status-not-confirmed_pop-up {
    max-width: 220px;
  }

  .cards__status-confirmed_pop-up,
  .cards__status-waiting-confirmed_pop-up {
    min-height: 80px;
  }

  .cards__status-confirmed_pop-up p,
  .cards__status-waiting-confirmed_pop-up p {
    max-width: 150px;
  }
}

@media (max-width: 350px) {
  .container {
    padding: 0 15px;
  }

  .nav {
    margin: 30px 0 38px;
  }

  .nav__inner {
    gap: 25px;
  }

  .search-form__input {
    padding: 10px 40px 10px 5px;
    width: 270px;
  }

  .nav__btn {
    padding: 12px 70px;
    border-radius: 9px;
  }
}

button {
  display: inline-block;
  cursor: pointer;
  border: none;
  background-color: transparent;
  padding: 0;
}

@font-face {
  font-family: 'Raleway-Light';
  src: url('~@/assets/fonts/Raleway-Light.ttf');
}

@font-face {
  font-family: 'Raleway-Regular';
  src: url('~@/assets/fonts/Raleway-Regular.ttf');
}

@font-face {
  font-family: 'Raleway-Medium';
  src: url('~@/assets/fonts/Raleway-Medium.ttf');
}

@font-face {
  font-family: 'Raleway-SemiBold';
  src: url('~@/assets/fonts/Raleway-SemiBold.ttf');
}

@font-face {
  font-family: 'Raleway-Bold';
  src: url('~@/assets/fonts/Raleway-Bold.ttf');
}

.container {
  max-width: 900px;
  padding: 0 50px;
  margin: 0 auto;
}

/* header */

.header {
  background: linear-gradient(90deg, rgb(222, 73, 189), rgb(173, 58, 226) 45%, rgb(123, 185, 245) 100%);
  padding: 31px 0 26px 0;
}

.header__inner {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.header__text {
  color: #ffffff;
}

.header__text h1 {
  font-family: 'Raleway-Bold';
  font-weight: 700;
  font-size: 24px;
  line-height: 24px;
  margin-bottom: 10px;
}

.header__text p {
  font-family: 'Raleway-Medium';
  font-size: 16px;
  line-height: 18px;
}

.header__link {
}

/* header end*/

/* section first screen */

/* .first-screen {
    margin: 70px 0 100px 0;
} */

.first-screen__inner {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.first-screen__inner img {
  max-width: 350px;
  margin-bottom: 10px;
}

.action-button {
  outline: none;
  background-color: #D546C4;
  padding: 12px 40px;
  border-radius: 10px;
  font-family: 'Raleway-SemiBold';
  color: #ffffff;
  font-size: 16px;
  line-height: 16px;
  margin-bottom: 20px;
  transition: all 0.3s ease-in-out;
}

.action-button:hover {
  background-color: #8A94F0;
}

.first-screen__inner p {
  font-family: 'Raleway-Medium';
  color: rgba(26, 25, 59, 0.65);
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  max-width: 430px;
}

.wrapper {
  padding: 40px 0;
}

input[type="file"] {
  display: none;
}

.content {
  opacity: 0;
  animation: ani 2.5s forwards;
}

.content__img {
  margin-bottom: 40px;
}

.content__buttons-item {
  margin-bottom: 20px;
  text-align: center;
}

.content__buttons-item:last-child {
  margin-bottom: 0;
}

input {
  border: none;
}

input:hover,
input:focus {
  outline: none;
  box-shadow: none;
}

.form {
  /* box-shadow: 1px 1px 3px rgba(0, 0, 0, .5); */
  /* box-shadow: 0px 0px 10px 1px #DCDCDC; */
  border-radius: 12px;
  margin-bottom: 40px;
}


.cards__status-confirmed::before {
  background: url(assets/images/confirmed.svg);
}

.cards__status-waiting-confirmed::before {
  background: url(assets/images/waiting_for_confirmation.svg);
}

.cards__status-not-confirmed::before {
  background: url(assets/images/need_to_make_a_change.svg);
}

.form__header,
.form__fields,
.form__footer {
  padding: 10px;
}

.form__header {
  /* background-color: #D546C4; */
  text-align: center;
}

.form__footer {
  text-align: center;
}

.form__title {
  font-size: 24px;
  line-height: 28px;
  font-weight: 700;
  color: #000000;
  text-align: center;
}

.form__group {
  position: relative;
  margin-bottom: 20px;
}

.form__group:last-child {
  margin-bottom: 0;
}

.form__input {
  position: relative;
  display: block;
  background: transparent;
  width: 100%;
  padding: 30px 10px 10px 5px;
  border-bottom: solid 2px #eee;
  transition: all .3s;
  z-index: 2;
}

.form__label {
  position: absolute;
  top: 0;
  margin-top: -10px;
  bottom: 8vh;
  left: 5px;
  font-size: 18px;
  line-height: 20px;
  font-weight: 500;
  transition: all .3s;
  z-index: 1;
  color: #000;
  margin-top: 5px;
}

.form__checkbox {
  position: relative;
}

.form__checkbox_flex {
  display: flex;
  justify-content: center;
}

.form__input-checkbox {
  position: absolute;
  width: 0;
  height: 0;
  border: none;
  opacity: 0;
  float: left;
}

.form__label-checkbox {
  display: flex;
  align-items: left;
  transition: all .3s;
  float: left;
}

.form__label-checkbox::before {
  content: '';
  display: block;
  width: 30px;
  height: 30px;
  flex: 0 0 30px;
  margin-right: 10px;
  border: solid 2px #D546C4;
  transition: all .3s;
  border-radius: 5px;
}

.form__input-checkbox:checked + .form__label-checkbox {
  color: #D546C4;
}

.form__input-checkbox:checked + .form__label-checkbox::before {
  background-image: url('assets/images/check.svg');
  background-color: #D546C4;
  background-repeat: no-repeat;
  background-position: center;
}

.form__input-radio {
  position: absolute;
  width: 0;
  height: 0;
  border: none;
  opacity: 0;
}

.form__label-radio {
  display: flex;
  align-items: center;
  transition: all .3s;
}

.form__label-radio::before {
  content: '';
  display: block;
  width: 20px;
  height: 20px;
  margin-right: 10px;
  border: solid 2px #D546C4;
  border-radius: 50%;
  transition: all .3s;
}

.form__input-radio:checked + .form__label-radio {
  color: #D546C4;
}

.form__input-radio:checked + .form__label-radio::before {
  background-color: #D546C4;
  box-shadow: inset 0 0 0 3px #fff;
}

.form__fieldset {
  margin-bottom: 80px;
}

.form__fieldset:last-child {
  margin-bottom: 0;
}

.cards__full_name {
  font-family: 'Raleway-Bold';
  line-height: 30px;
  font-size: 22px;
  margin: 35px 0 28px;
}

.cards__list {
  padding: 18px 12px 40px;
  margin-bottom: 18px;
}

.cards__list_pop-up {
  padding: 18px 25px 30px;
  margin: 30px 0 35px;
}

.form__legend {
  margin-bottom: 20px;
  float: none;
  font-size: 20px;
  line-height: 22px;
  font-weight: 700;
  color: #D546C4;
}

.fieldset-list__item {
  margin-bottom: 10px;
}

.fieldset-list__item:last-child {
  margin-bottom: 0;
}

.form__privacy {
  margin-bottom: 20px;
  text-align: left;
  float: left;
}

.form__add-fields {
  margin-top: 20px;
  padding-left: 10px;
  font-size: 18px;
  line-height: 20px;
  color: #D546C4;
  cursor: pointer;
}

/* 
.nav__flex-between {
  display: flex;
  justify-content: space-between;
} */

.button {
  cursor: pointer;
  display: inline-block;
  padding: 15px 10px;
  width: 100%;
  max-width: 320px;
  background-image: linear-gradient(45deg, #00ffff 0%, #D546C4 50%, #990066 100%);
  background-position: 100% 0;
  background-size: 200% 200%;
  font-size: 18px;
  line-height: 20px;
  color: #fff;
  text-decoration: none;
  text-align: center;
  border: none;
  box-shadow: 0 10px 20px 0 rgba(0, 0, 0, .5);
  transition: all .5s;
}

.button:hover {
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  background-position: 0 0;
  color: #fff;
  text-decoration: none;
}

.top__30px {
  margin-top: 30px !important;
}

.form__error {
  color: red;
  margin-top: -15px;
}

@keyframes ani {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@media (max-width: 991.98px) {
  .form__fieldset {
    margin-bottom: 50px;
  }
}

@media (max-width: 767.98px) {

  .form__header,
  .form__fields,
  .form__footer {
    padding: 30px;
  }

  .form__legend {
    font-size: 18px;
    line-height: 20px;
  }
}

@media (max-width: 479.98px) {

  .form__header,
  .form__fields,
  .form__footer {
    padding: 20px;
  }
}

.bounce-enter-active {
  animation: bounce-in .5s;
}

.bounce-leave-active {
  animation: bounce-out .5s;
}

.btn-position__center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}


.box {
  cursor: pointer;
  position: relative;
  width: 280px;
  height: 430px;
  color: #fff;
  background: #111;

  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px 30px;
  transition: 0.5s;
}


.box:hover {
  transform: translateY(-20px);
}

.box::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #4158D0;
  background-image: linear-gradient(45deg, #00ffff 0%, #D546C4 50%, #990066 100%);
}

.box::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #4158D0;
  background-image: linear-gradient(45deg, #00ffff 0%, #D546C4 50%, #990066 100%);
}

.box:nth-child(2)::after,
.box:nth-child(2)::before {
  background-color: #FAD961;
  background-image: linear-gradient(45deg, #FAD961, #F76B1C);
}

.box:nth-child(3)::after,
.box:nth-child(3)::before {
  background-color: #FA8BFF;
  background-image: linear-gradient(45deg, #00ffff 0%, #D546C4 50%, #990066 100%);
}

.box span {
  position: absolute;
  top: 6px;
  left: 6px;
  right: 6px;
  bottom: 6px;
  background: #000;
  z-index: 2;
  opacity: 1;
}

.box span::before {
  content: '';
  position: absolute;
  width: 50%;
  height: 100%;
  background: #fff;
  opacity: .1;
  pointer-events: none;
}

.cards__price_pop-up h5 {
  font-size: 22px;
  line-height: 28px;
}

.cards__price_pop-up h4 {
  font-size: 25px;
  line-height: 35px;
}

.cards__status-confirmed,
.cards__status-waiting-confirmed,
.cards__status-not-confirmed {
  display: flex;
  position: relative;
}

.cards__status-confirmed::before,
.cards__status-waiting-confirmed::before,
.cards__status-not-confirmed::before {
  content: '';
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 22.5px;
  height: 22.5px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.cards__status-confirmed::before {
  background: url('assets/images/confirmed.svg');
}

.cards__btn-expand button {
  position: relative;
  font-family: 'Raleway-Regular';
  font-size: 14px;
  line-height: 14px;
  padding: 6px 32px 6px 6px;
}

.cards__btn-expand button::after {
  content: '';
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 18px;
  height: 18px;
  background: url('assets/images/expand.svg');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.cards__btn-expand button:hover {
  text-decoration: underline;
}

.cards__price {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;
}

.cards__price h5 {
  font-size: 18px;
  line-height: 22px;
}

.box .content {
  flex-grow: 1;
  position: relative;
  z-index: 10;
  padding: 30px 40px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  height: 100%;
  width: 100%;
}

.box .content h2 {
  font-size: 28px;
  margin-top: 0;
}

.box .content p {
  font-size: 18px;
  line-height: 28px;
  letter-spacing: .5px;
}

.box .content a {
  display: inline-block;
  color: #000;
  font-weight: bold;
  font-size: 16px;
  text-transform: uppercase;
  text-decoration: none;
  padding: 8px 25px;
  border-radius: 4px;
  margin-top: auto;
  background: #fff;
  background-image: linear-gradient(35deg, #fdfcfb 0%, #e2d1c3 100%);
}

.lk__container {
  max-width: 800px;
}

.catalog__position {
  display: flex;
  justify-content: flex-start;
}

@keyframes bounce-in {
  0% {
    transform: scale(0);
    max-height: 0;
  }

  50% {
    transform: scale(1.3);
    max-height: 30rem;
  }

  100% {
    transform: scale(1);
    max-height: 30rem;
  }
}

@keyframes bounce-out {
  0% {
    transform: scale(1);
    max-height: 30rem;
  }

  50% {
    transform: scale(1.1);
    max-height: 30rem;
  }

  100% {
    transform: scale(0);
    max-height: 0;
  }
}

/* переопределние кастомных стилей ui библиотеки */
.mdc-button--raised:not(:disabled) {
  background-color: #d546c4 !important;
}

/* section first screen end */

@media (max-width: 650px) {
  .container {
    padding: 0 30px;
  }

  .header {
    padding: 20px 0;
  }

  .first-screen {
    margin: 50px 0 80px 0;
  }
}

@media (max-width: 500px) {

  .container {
    padding: 0 25px;
  }

  .header__text h1 {
    margin-bottom: 7px;
  }

  .header__link img {
    width: 28px;
  }

  .first-screen__inner img {
    width: 300px;
  }

  .first-screen {
    margin: 30px 0 50px 0;
  }
}

@media (max-width: 450px) {
  .container {
    padding: 0 20px;
  }

  .second-participant-screen__primary_btn {
    padding: 13px 30px !important;
    font-size: 14px !important;
  }

  .description {
    font-size: 14px !important;
  }

  .primary__btn {
    margin: 10px 0;
  }

  .secondary__btn {
    text-align: center !important;
    font-size: 14px !important;
  }

  .first-screen__inner img {
    width: 260px;
  }

  .first-screen__inner p {
    max-width: 280px;
  }
}

@media (max-width: 400px) {
  .header__text p {
    max-width: 200px;
  }

  .first-screen__inner p {
    max-width: 280px;
  }
}

@media (max-width: 350px) {
  .container {
    padding: 0 15px;
  }
}


@media (max-width: 360px) {
  .cards__pop-ap__inner {
    border-top-right-radius: none !important;
    border-bottom-right-radius: none !important;
    padding: 15px;
  }

  .cards__full_name_pop-up {
    margin: 10px 0 18px;
  }

  .cards__list_pop-up {
    padding: 15px 15px 20px;
    margin-bottom: 28px;
  }

  .cards__status-confirmed_pop-up,
  .cards__status-waiting-confirmed_pop-up,
  .cards__status-not-confirmed_pop-up {
    max-width: 220px;
  }

  .cards__status-confirmed_pop-up,
  .cards__status-waiting-confirmed_pop-up {
    min-height: 80px;
  }

  .cards__status-confirmed_pop-up p,
  .cards__status-waiting-confirmed_pop-up p {
    max-width: 150px;
  }
}

.error-container {
  max-height: 100px;
  overflow-y: auto;
  background-color: #f8d7da;
  border-radius: .25rem;
  margin-top: .25rem;
  margin-bottom: 1rem;
  font-size: 0.8rem;
  padding: 0.2rem 0.5rem;
  color: #721c24;
}

.error-container ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.error-container li {
  margin-left: 1rem;
}
</style>
