<template>
    <form>
        <input class="form__input" type="text" placeholder="ФИО" />
        <input class="form__input" type="text" placeholder="Телефон" />

        <div class="status-form_footer">
            <button class="action-button">Отправить</button>
        </div>
    </form>
</template>

<script>
export default {
    data() {

    }
}
</script>

<style></style>