<template>
    <div class="cards__status cards__status-waiting-confirmed" v-if="status === 'Ожидание'">
        <p class="cards__status-top">Ждёт подтверждения</p>
    </div>

    <div class="cards__status cards__status-waiting-confirmed" v-if="status === 'Ожидает оплаты'">
        <p class="cards__status-top">Ждёт оплаты</p>
    </div>

    <div class="cards__status cards__status-confirmed" v-if="status === 'Подтверждён'">
        <p class="cards__status-top">Заявка подтверждена</p>
    </div>

    <div class="cards__status cards__status-confirmed" v-if="status === 'Оплачен'">
        <p class="cards__status-top">Оплачен</p>
    </div>

    <div class="cards__status cards__status-not-confirmed" v-if="status === 'Отклонён'">
        <p class="cards__status-top">Заявка отклонена</p>
    </div>
</template>

<script>
export default {
    props: ['status'],
    data() {
        return {

        }
    }
}
</script>

<style scoped>

.cards__status-top {
    padding-top: 18px;
}

.cards__status-confirmed  {
    color: #5e9a30 !important;
}

.cards__status-waiting-confirmed  {
    color: #FFC000 !important;
}

.cards__status-not-confirmed  {
    color: #ED2939 !important;
}
</style>