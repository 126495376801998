<template>
   <fieldset class="form__fieldset">
        <legend class="form__legend">{{ formTitle }}</legend>
        <div class="fieldset-list">
            <div class="fieldset-list__item" v-for="item in usersLevel" :key="item.id">
                <div class="form__radio">
                    <input @input="$emit('update:modelValue', $event.target.value)" :type="item.type" :value="item.value" :id="'level-' + item.id" name="level" class="form__input-radio" v-model="v$.selected.$model">
                    <label :for="'level-' + item.id" class="form__label-radio">{{ item.title }}</label>
                </div>
            </div>
        </div>
    </fieldset>
    <p v-for="(error, index) in v$.selected.$errors" :key="index" style="margin-top: -35px;" class="error-container form__error">{{ error.$message }}</p> 
</template>

<script>

const usersLevel = [
    {
        id: 1,
        title: "Дебют",
        value: "Дебют",
        type:  "radio",
    },
    {
        id: 2,
        title: "Начинающие",
        value: "Начинающие",
        type: "radio",
    },
    {
        id: 3,
        title: "Продвинутые",
        value: "Продвинутые",
        type: "radio",
    },
    {
        id: 4,
        title: "Профессионалы",
        value: "Профессионалы",
        type: "radio",
    },
    {
        id: 5,
        title: "Элита",
        value: "Элита",
        type: "radio",
    }
]

import { useVuelidate } from '@vuelidate/core'
import { required, helpers } from '@vuelidate/validators'

export default {
    props: ['modelValue'],
    emits: ['update:modelValue'],
    data() {
        return {
            usersLevel,
            selected: this.modelValue,
            formTitle: 'Укажите уровень подготовки',
        }
    },
    setup() {
        return { v$: useVuelidate() }
    },
    validations: {
        selected: {
            required: helpers.withMessage('Поле "Уровень подготовки" обязательно для заполнения', required)
        },
    },
}
</script>

<style></style>