<template>
    <fieldset class="form__fieldset">
        <legend class="form__legend">{{ formTitle }}</legend>
        <div class="fieldset-list">
            <div class="fieldset-list__item" v-for="item in soloOrDuets" :key="item">
                <div class="form__radio">
                    <input v-model="v$.selected.$model" :type="item.type" :id="item.id" :value="item.value" name="solo-duet" class="form__input-radio">
                    <label :for="item.id" class="form__label-radio">{{ item.value }}</label>
                </div>
            </div>
        </div>
    </fieldset>
    <p v-for="(error, index) in v$.selected.$errors" :key="index" style="margin-top: -35px;" class="error-container form__error">{{ error.$message }}</p> 
</template>
<script>

const soloOrDuets = [
    {
        id: "solo",
        type: "radio",
        value: "соло",
    },
    {
        id: "duet",
        type: "radio",
        value: "дуэт"
    }
]

import { useVuelidate } from '@vuelidate/core'
import { required, helpers } from '@vuelidate/validators'

export default {
    data() {
        return {
            formTitle: 'Укажите количество участников',
            selected: '',
            soloOrDuets,
        }
    },
    setup() {
        return { v$: useVuelidate() }
    },
    validations: {
        selected: {
            required: helpers.withMessage('Поле "Укажите количество участников" обязательно для заполнения', required)
        },
    },
    watch: {
        selected: function () {
            this.$emit('getPerformanceType', this.selected)
        }
    },
    methods: {

    }
}
</script>

<style></style>
