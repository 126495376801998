<template>
      <section class="third-participant-screen">
        <div class="container third-participant-screen__inner">
            <router-link to="/participant">
                <button class="back__btn">
                    <svg width="81" height="42" viewBox="0 0 81 42" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <circle cx="21" cy="21" r="21"/>
                        <path d="M27.2642 15.75L20.3951 19.6786C20.3951 19.6786 19.3105 20.2471 19.3105 20.75C19.3105 21.2529 20.3951 21.8214 20.3951 21.8214L27.2642 25.75" stroke-width="2.7" stroke-linecap="round"/>
                        <path d="M21.4655 20.7498H79.3106" stroke-width="2.7" stroke-linecap="round"/>
                    </svg>                                     
                </button>
            </router-link>
            <div class="third-participant-screen__content content">
                <p class="description">Пожалуйста, укажите данные участника.</p>
                <div class="form">
                
                    <div v-for="item in inputs" :key="item" class="data-form">
                        <input  class="data-form__input data-form__input_full-name" type="text" placeholder="ФИО">
                        <button @click="add" class="data-form__btn-icon data-form__btn-icon-plus">   
                            <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <circle cx="15" cy="15" r="15"/>
                                <path d="M22.1421 15.0711H7.99998" stroke="#737373" stroke-width="1.5" stroke-linecap="round"/>
                                <path d="M15.1441 8.21311V22.3552" stroke="#737373" stroke-width="1.5" stroke-linecap="round"/>
                            </svg>                                                 
                        </button>
                        <button v-if="this.inputs > 1" @click="remove" class="data-form__btn-icon data-form__btn-icon-minus">  
                            <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <circle cx="15" cy="15" r="15"/>
                                <path d="M22.1421 15.0711H7.99998" stroke="#737373" stroke-width="1.5" stroke-linecap="round"/>
                            </svg>                                                  
                        </button>
                    </div>

                    <!-- <div class="data-form">
                        <input class="data-form__input data-form__input_full-name" type="text" placeholder="ФИО">
                        <button class="data-form__btn-icon data-form__btn-icon-minus">  
                            <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <circle cx="15" cy="15" r="15"/>
                                <path d="M22.1421 15.0711H7.99998" stroke="#737373" stroke-width="1.5" stroke-linecap="round"/>
                            </svg>                                                  
                        </button>
                    </div> -->

                    <div class="data-form" v-if="this.inputs > 1">
                        <input class="data-form__input" type="text" placeholder="Кем вы являетесь участнику ?">
                    </div>

                    <div class="data-form">
                        <input class="data-form__input" type="tel" placeholder="Телефон">
                    </div>

                    <div class="form__privacy">
                        <div class="form__checkbox form__checkbox_flex">
                            <input type="checkbox" id="check-privacy" class="form__input-checkbox">
                            <label for="check-privacy" class="form__label-checkbox">Отправляя форму вы&nbsp;соглашаетесь на&nbsp;обработку персональных данных</label>
                        </div>
                    </div>

                    <div class="data-form__btn-send-flex">
                        <button @click="checkStatus()" class="data-form__btn-send__btn primary__btn">Отправить</button>
                    </div>
                </div>
                
            </div>
        </div>
    </section>
</template>

<script>
export default {
    data() {
        return {
            inputs: 1,
        }
    },
    methods: {
        checkStatus() {
            this.$router.push('success-request')
        },
        add() {
            this.inputs++
        },
        remove() {
            this.inputs--
        }
    }
}
</script>

<style>

</style>