<template>
    <!-- <router-link to="/participant">
        <ui-fab icon="keyboard_backspace" style="margin-top: 30px;"></ui-fab>
    </router-link> -->

    <section class="nav">
        <div class="container nav__inner">
            
            <form action="#" class="search-form">
                <input v-model="search" class="search-form__input" type="text" placeholder="Найти участника">
            </form>

            <router-link to="signup">
                <button class="nav__btn">Подать заявку</button>
            </router-link>            

        </div>
    </section>

    <h2 style="margin-left: 50px;" v-if="!this.performanceFilteredArr.length">Ничего не найдено</h2>

    <ui-grid>
        <CardPreview v-for="performance in performanceFilteredArr" :performance="performance"
            :key="performance.id" />
    </ui-grid>
</template>

<script>
import CardPreview from '@/components/Performance/PreviewCard.vue'

export default {
    components: { CardPreview },
    data() {
        return {
            search: ''
        }
    },
    computed: {
        performanceFilteredArr() {
            let self = this;

            return this.$store.state.vkUserPerformances.filter(function (performance) {

                let fio = `${performance.user.surname} ${performance.user.name} ${performance.user.patronymic}`
                
                return performance.user.name.toLowerCase().includes(self.search.toLowerCase()) ||
                        performance.user.surname.toLowerCase().includes(self.search.toLowerCase()) ||
                        performance.user.patronymic.toLowerCase().includes(self.search.toLowerCase()) ||
                        fio.toLowerCase().includes(self.search.toLowerCase())
            })
        }
    },
    methods: {
        
    }
}
</script>

<style></style>