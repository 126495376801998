import {createRouter, createWebHistory} from 'vue-router'
import StartView from '../views/StartView.vue'
import SignupView from '../views/SignupView.vue'
import StopView from '../views/StopView.vue'
import PersonalAreaView from '../views/PersonalAreaView.vue'
import PermissionDinedView from '../views/PermissionDinedView.vue'
import IdentificationView from '../views/IdentificationView.vue'
import StatementParticipantView from '../views/StatementParticipantView.vue'
import StatementCoachView from '../views/StatementCoachView.vue'
import IdentificationCoachView from '../views/IdentificationCoachView.vue'
import MultipleDownloadView from '../views/MultipleDownloadView.vue'
import CheckStatusView from '../views/CheckStatusView.vue'
import SuccessRequestView from '../views/SuccessRequestView.vue'
import CoachStartView from '../views/CoachStartView.vue'
import CheckCoachView from '../views/CheckCoachView.vue'
import store from '@/store'

const routes = [
    {
        path: '/start',
        name: 'startView',
        component: StartView,
    },
    {
        path: '/identification',
        name: 'identification',
        component: IdentificationView,
    },
    {
        path: '/participant',
        name: 'statementManager',
        component: StatementParticipantView,
    },
    {
        path: '/coach',
        name: 'statementCoach',
        component: StatementCoachView
    },
    {
        path: '/coach-identification',
        name: 'identificationCoach',
        component: IdentificationCoachView,
    },
    {
        path: '/multiple-signup',
        name: 'multipleDownload',
        component: MultipleDownloadView,
    },
    {
        path: '/check-status',
        name: 'checkStatus',
        component: CheckStatusView,
    },
    {
        path: '/signup',
        name: 'signup',
        component: SignupView,
    },
    {
        path: '/stop',
        name: 'stop',
        component: StopView,
    },
    {
        path: '/personal-area',
        name: 'personalArea',
        component: PersonalAreaView,
    },
    {
        path: '/permission-dined',
        name: 'permissionDined',
        component: PermissionDinedView,
    },
    {
        path: '/success-request',
        name: 'SuccessRequest',
        component: SuccessRequestView
    },
    {
        path: '/coach-start',
        name: 'CoachStart',
        component: CoachStartView
    },
    {
        path: '/check-coach',
        name: 'CheckCoach',
        component: CheckCoachView
    },
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

export default router

router.beforeEach((to, from, next) => {
    if (to.name == 'startView' && store.state.vkUserPerformances.length > 0) next({name: 'personalArea'})
    else next()
})