<template>
  <fieldset class="form__fieldset">
    <legend class="form__legend">{{ formTitle }}</legend>
    <div class="fieldset-list">
      <div class="fieldset-list__item" v-for="item in cityPerformance" :key="item.id">
        <div class="form__radio">
          <input @input="$emit('update:modelValue', $event.target.value)" :type="item.type" :value="item.value" :id="'performance-' + item.id" name="city" class="form__input-radio" v-model="v$.selected.$model">
          <label :for="'performance-' + item.id" class="form__label-radio">{{ item.title }}</label>
        </div>
      </div>
    </div>
  </fieldset>
  <p v-for="(error, index) in v$.selected.$errors" :key="index" style="margin-top: -35px;" class="error-container form__error">{{ error.$message }}</p>
</template>

<script>

const cityPerformance = [
  {
    id: 1,
    title: "Сочи, 17-19 мая",
    value: 1,
    type:  "radio",
  },
  {
    id: 2,
    title: "Санкт-Петербург, 7-9 июня",
    value: 2,
    type: "radio",
  },

]

import { useVuelidate } from '@vuelidate/core'
import { required, helpers } from '@vuelidate/validators'

export default {
  props: ['modelValue'],
  emits: ['update:modelValue'],
  data() {
    return {
      cityPerformance,
      selected: this.modelValue,
      formTitle: 'Укажите город выступления',
    }
  },
  setup() {
    return { v$: useVuelidate() }
  },
  validations: {
    selected: {
      required: helpers.withMessage('Поле "Город выступления" обязательно для заполнения', required)
    },
  },
}
</script>

<style></style>